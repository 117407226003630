import React from 'react'

import { StyledContainer, StyledGrid, StyledGridItem, StyledH2, StyledP, StyledIcon } from './Intro.style'

import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'

const AboutIntro = () => {
  return (
    <StyledContainer>
      <StyledGrid container spacing={3}>
        <StyledGridItem item md={8} sm={12}>
          <Container>
            <StyledH2>We're a Passionate Team of Software Developers</StyledH2>
            <p>
              Our mission is to produce sustainable growth for our clients. By utilizing the latest technology, we can
              craft engaging experiences that build relationships, solve problems, and revolutionize the standard of
              software.
            </p>

            <Divider />

            <StyledIcon icon={faQuoteLeft} aria-hidden="true" />

            <StyledP>
              We don't just make software. We offer new perspectives that elevate our clients' expectations.
            </StyledP>

            <Divider />
          </Container>
        </StyledGridItem>
      </StyledGrid>
    </StyledContainer>
  )
}
export default AboutIntro
