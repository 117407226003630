import styled, { css } from 'styled-components'

import colors from '../../../styles/colors'
import { device } from '../../../styles/media'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const StyledContainer = styled(Container)`
  color: ${colors.textDark};
  height: auto;
  min-height: 500px;

  @media ${device.tablet} {
    text-align: center;
  }
`

export const StyledGrid = styled(Grid)`
  height: 100%;
  padding-bottom: 100px;
  padding-top: 100px;
`

export const StyledGridItem = styled(Grid)`
  height: 100%;

  @media ${device.tablet} {
    ${props =>
      props.image &&
      css`
        display: none;
      `}
  }
`

export const StyledH2 = styled.h2`
  font-size: 3rem;
  font-weight: 400;
`

export const StyledImg = styled.img`
  height 100%;
  width: 100%;
`

export const StyledP = styled.p`
  color: ${colors.textMedium};
  padding: 0 5rem;

  @media ${device.mobile} {
    padding: 0;
  }
`
export const StyledIcon = styled(FontAwesomeIcon)`
  color: ${colors.textMedium};
  float: left;
  font-size: 2.5rem;
  padding-left: 1rem;
  padding-top: 1rem;
  width: 2rem;

  @media ${device.tablet} {
    display: none;
  }
`
