import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import styled, { css } from 'styled-components'

import colors from '../../../styles/colors'
import { device } from '../../../styles/media'

export const StyledContainer = styled(Container)`
  min-height: 600px;
`

export const StyledDiv = styled.div`
  background-color: #f9f8f8;
  color: ${colors.textDark};
`

export const StyledGrid = styled(Grid)`
  height: 100%;
`

export const StyledGridItem = styled(Grid)`
  min-height: 300px;
  padding: 3.0rem 4rem;
  width: 100%;

  ${props =>
    props.first &&
    css`
      border-right: 1px solid gray;
      border-bottom: 1px solid gray;
    `}
  ${props =>
    props.second &&
    css`
      border-bottom: 1px solid gray;
    `}
  ${props =>
    props.third &&
    css`
      border-left: 1px solid gray;
      border-bottom: 1px solid gray;
    `}
  ${props =>
    props.fourth &&
    css`
      border-right: 1px solid gray;
    `}
  ${props =>
    props.sixth &&
    css`
      border-left: 1px solid gray;
      @media ${device.tablet} {
      }
    `}

  @media ${device.tablet}{
    height: 200px;
    padding-top: 1.2rem;
    ${props =>
      props.first &&
      css`
        border-right: 1px solid gray;
        border-bottom: 1px solid gray;
      `}
    ${props =>
      props.second &&
      css`
        border-bottom: 1px solid gray;
      `}
    ${props =>
      props.third &&
      css`
        border-right: 1px solid gray;
        border-bottom: 1px solid gray;
        border-left: none;
      `}
    ${props =>
      props.fourth &&
      css`
        border-bottom: 1px solid gray;
        border-right: none;
      `}
    ${props =>
      props.fifth &&
      css`
        border-right: 1px solid gray;
      `}
    ${props =>
      props.sixth &&
      css`
        border: none;
      `}
}

  @media ${device.mobile}{
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    border-left: 0;
    border-right: 0;
  }
`

export const StyledH2 = styled.h2`
  margin-top: 0;
  font-weight: 400;
  @media ${device.tablet} {
    margin-bottom: 0;
  }
`
export const StyledP = styled.p`
  color: ${colors.textMedium};

  @media ${device.tablet} {
    margin-bottom: 0 !important;
    margin-top: 0.7rem !important;
  }
`
export const StyledSpan = styled.div`
  color: ${colors.textMedium};
  padding-bottom: 0.1rem;
`
