import React from 'react'

import { MainShape, StyledContainer, StyledH2, StyledLink, StyledP, StyledSpan } from './Main.style'

import Grid from '@material-ui/core/Grid'

const Main = () => (
  <Grid container>
    <Grid item lg={false}></Grid>
    <MainShape item>
      <StyledContainer>
        <StyledH2>A Few Words About Us</StyledH2>
        <StyledP>
          <StyledLink to="/">Home</StyledLink>
          <StyledSpan> / About Us</StyledSpan>
        </StyledP>
      </StyledContainer>
    </MainShape>
  </Grid>
)

export default Main
