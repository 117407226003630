import React from 'react'

import { StyledContainer, StyledDiv, StyledGridItem, StyledGrid, StyledH2, StyledP, StyledSpan } from './JoinUs.style'

const JoinUs = () => {
  const reasonsToJoin = [
    {
      number: '01',
      title: 'Creative Space',
      content: 'Nothing is out of reach. We strive to constantly push our limits in design and effectiveness.',
    },
    { number: '02', title: 'Learn', content: "Identify, understand, and document our client's needs." },
    { number: '03', title: 'Build', content: 'Deliver software that transforms the problem.' },
    { number: '04', title: 'Analyze', content: 'Discover new insights and review analytics.' },
    { number: '05', title: 'Refine', content: 'Integrate improvements for continual growth and sustainability.' },
    {
      number: '06',
      title: 'Quality',
      content: "We build only the highest quality products, not satisfied until it's perfect.",
    },
  ]

  const renderGridItems = reasonsToJoin.map((reason, index) => {
    return (
      <StyledGridItem
        first={index === 0}
        second={index === 1}
        third={index === 2}
        fourth={index === 3}
        fifth={index === 4}
        sixth={index === 5}
        item
        md={4}
        sm={6}
        key={index}
      >
        <StyledH2>
          <StyledSpan>{reason.number}</StyledSpan>
          {reason.title}
        </StyledH2>
        <StyledP>{reason.content}</StyledP>
      </StyledGridItem>
    )
  })

  return (
    <StyledDiv id="why-us">
      <StyledContainer>
        <StyledGrid container>{renderGridItems}</StyledGrid>
      </StyledContainer>
    </StyledDiv>
  )
}

export default JoinUs
