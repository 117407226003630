import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'

import Intro from '../components/About/Intro/Intro'
import JoinUs from '../components/About/JoinUs/JoinUs'
import Main from '../components/About/Main/Main'
import OurClients from '../components/About/OurClients/OurClients'

const About = () => (
  <Layout>
    <SEO title="About" />

    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content="Learn how we can transform your expectations of custom software." />
      <title>About | LaMar Software</title>
      <link rel="canonical" href="https://lamarsoftware.io/about" />
    </Helmet>

    <Main />
    <Intro />
    <JoinUs />
    <OurClients />
  </Layout>
)

export default About
