import colors from '../../../styles/colors'
import { device } from '../../../styles/media'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

export const StyledContainer = styled(Container)`
  text-align: center;
  padding-top: 3.5rem;
`

export const StyledGrid = styled(Grid)`
  margin: 0;
  text-align: center;
  width: 100%;
`

export const StyledH2 = styled.h2`
  color: ${colors.textMedium};
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 0;
  padding-bottom: 0;
`

export const StyledP = styled.p`
  color: ${colors.textMedium};
  font-size: 1.3rem;
  font-weight: 700;
`

export const StyledSection = styled.div`
  align-items: center;
  background: #323334;
  color: ${colors.textDark};
  display: flex;
  min-height: 250px;

  @media ${device.tablet} {
    min-height: 600px;
  }

  @media ${device.mobile} {
    min-height: 850px;
  }
`
export const StyledLogo = styled.img`
  height: 100%;
  margin-bottom: 21px;
  margin-top: 21px;
  max-width: 300px;
  width: 100%;
`
