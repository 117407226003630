import React from 'react'

import { StyledGrid, StyledSection, StyledLogo } from './OurClients.style'

import logo1 from '../../../images/logo_agproz.png'
import logo2 from '../../../images/logo_qcify.png'
import logo3 from '../../../images/logo_langers.png'
import logo4 from '../../../images/logo_varsity.png'
import logo5 from '../../../images/logo_wf.png'

import Grid from '@material-ui/core/Grid'

const OurClients = () => {
  return (
    <StyledSection id="clients">
      <StyledGrid container justify="center" alignItems="center" spacing={3}>
        <Grid item xs={12} sm={6} md>
          <StyledLogo src={logo1} alt="AgProz logo" />
        </Grid>

        <Grid item xs={12} sm={6} md>
          <StyledLogo src={logo2} alt="Qcify logo" />
        </Grid>

        <Grid item xs={12} sm={6} md>
          <StyledLogo src={logo3} alt="Langers Juice logo" />
        </Grid>

        <Grid item xs={12} sm={6} md>
          <StyledLogo src={logo4} alt="Varsity Technologies logo" />
        </Grid>

        <Grid item xs={12} sm={6} md>
          <StyledLogo src={logo5} alt="Workforce Development logo" />
        </Grid>
      </StyledGrid>
    </StyledSection>
  )
}

export default OurClients
